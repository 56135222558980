jQuery(function ($) {
  // meieki
  var meieki = $('.meieki-banner');
  var meiekiClose = $('.meieki-closeBtn');
  var flag = true;
  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 1300) {
      // 1300以上になったら
      meieki.fadeOut('slow'); // 消す
    } else {
      // 1300未満になったら
      if (flag === true) {
        meieki.fadeIn('fast'); // 出す
      }

      meiekiClose.on('click', function () {
        meieki.hide();
        flag = false;
      });
    }
  });
});
jQuery(function ($) {
  // holiday
  var holiday = $('.holiday-banner');
  var holiClose = $('.holiday-closeBtn');
  var flag = true;
  $(window).on('scroll', function () {
    if ($(this).scrollTop() > 1300) {
      holiday.fadeOut('slow');
    } else {
      if (flag === true) {
        holiday.fadeIn('fast');
      }
      holiClose.on('click', function () {
        holiday.hide();
        flag = false;
      });
    }
  });
});